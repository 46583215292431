.component {
  & > .menuMobile {
    position: fixed;
    top: var(--site-header-mobile-height);
    left: 0;
    height: 100%;
    width: 100%;
    max-height: calc(100% - var(--site-header-mobile-height));
  }

  & > .inner {
    height: 100%;
  }
}

.inner {
  display: flex;
  align-items: center;
}

.menuMobile {
  background-color: var(--color-white);
}

.buttonMenu {
  background-color: var(--color-gray-sand);
  padding: var(--size-5) var(--size-30);
  color: var(--color-green-deep);
  border-radius: var(--size-20);
}

.buttonCross {
  background-color: var(--color-green-forrest);
  color: var(--color-white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > .icon {
    width: 16px;
    height: 16px;
  }
}

.componentMenuButton {
  & > .buttonCross {
    width: 35px;
    height: 35px;
  }
}
