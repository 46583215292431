.component {
  font-size: var(--font-size-16);
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > :not(:last-child) {
    margin-right: var(--size-20);
  }

  & > .meta {
    margin-top: var(--size-10);

    @media (--viewport-md) {
      margin-top: 0;
    }
  }
}

.container {
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: var(--size-20);
  }

  & > .image {
    width: var(--size-60);
    height: var(--size-60);
  }
}

.info {
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    @media (--viewport-md) {
      margin-right: var(--size-20);
    }
  }

  @media (--viewport-md) {
    flex-direction: row;
  }
}

.name {
  font-weight: var(--font-weight-bold);
}

.image {
  border-radius: 50%;
  overflow: hidden;

  & > * {
    height: 100%;
  }
}

.readingTime {
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: var(--size-10);
  }
}

.date {
  opacity: 0.5;
}

.meta {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > :not(:last-child) {
    margin-right: var(--size-20);
  }
}
