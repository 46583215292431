.componentMainContent {
  & > * {
    margin-bottom: var(--size-20);
  }

  & > .paragraph:empty {
    height: var(--size-20);
    margin-bottom: 0;
  }

  & > .heading {
    margin-bottom: var(--size-10);
    margin-top: var(--size-40);

    @media (--viewport-md) {
      margin-top: var(--size-70);
    }
  }

  & > .subheading {
    margin-bottom: var(--size-10);
    margin-top: var(--size-40);

    @media (--viewport-md) {
      margin-top: var(--size-70);
    }
  }

  & > :first-child {
    margin-top: 0;

    @media (--viewport-md) {
      margin-top: 0;
    }
  }

  & > :last-child {
    margin-bottom: 0;
  }

  /* stylelint-disable kaliber/selector-policy */
  /* reason: dealing with WYSIWYG content */
  & > .heading + *,
  & > .subheading + * {
    margin-top: var(--size-10);
  }

  & > .heading + .subheading,
  & > .heading + .list,
  & > .subheading + .list {
    margin-top: var(--size-20);
  }
  /* stylelint-enable kaliber/selector-policy */
}

.listOrdered {
  padding-left: 1.5em;
  list-style-type: decimal;
}

.listUnordered {
  padding-left: 1.5em;
  list-style-type: disc;
}
