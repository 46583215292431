.component {
  & > .caption {
    margin-top: var(--size-10);
  }
}

.caption {
  font-size: var(--font-size-12);
  opacity: 0.5;
}
