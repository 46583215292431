.component {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;

  & > .item {
    &.isNumber {
      width: var(--size-40);
    }

    &.isPadding {
      width: var(--size-10);
    }

    &.isPrevious {
      @media (--viewport-md) {
        margin-right: var(--size-70);
      }
    }

    &.isNext {
      @media (--viewport-md) {
        margin-left: var(--size-70);
      }
    }
  }
}

.item {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-18);

  &.isCurrentPage {
    color: var(--cta-color);

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      width: var(--size-40);
      height: var(--size-40);
      background-color: var(--cta-background-color);
      border-radius: var(--size-20);
    }
  }

  &.isPrevious {
    font-size: var(--font-size-16);

    & > .label {
      margin-left: 10px;
    }
  }

  &.isNext {
    font-size: var(--font-size-16);

    & > .label {
      order: -1;
      margin-right: 10px;
    }
  }
}

.label {
  font-size: 0;

  @media (--viewport-md) {
    font-size: unset;
  }
}
