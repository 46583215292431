.componentBase {
  text-decoration: underline;
}

.component {
  text-decoration: none;
}

.componentUnderlined {
  text-decoration: underline;
}

.componentUnderlinedOnInteraction {
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

.componentMenu {
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: var(--font-size-16);
  text-decoration: none;

  &.isActive {
    position: relative;

    &::before {
      border-top: 4px solid var(--color-green-lime);
      content: '';
      position: absolute;
      height: 4px;
      width: 100%;
      top: -15px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  & > :not(:last-child) {
    margin-right: var(--size-10);
  }
}

.componentMenuMobile {
  font-size: var(--font-size-20);
  color: var(--color-green-deep);
  text-decoration: none;
  padding: var(--size-20);
}
