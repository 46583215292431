.component {
  --height: 540px;

  position: relative;
  z-index: 0;
  padding-top: var(--size-60);
  padding-bottom: var(--size-60);

  @media (--viewport-md) {
    padding-bottom: var(--size-90);
  }

  & > .layout {
    position: relative;
    z-index: 2;
  }

  & > .radial {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    height: 100%;
  }

  & > .animation {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.layout {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  gap: var(--size-20);

  & > * {
    @supports not (gap: var(--size-20)) {
      margin-bottom: var(--size-20);
    }
  }
}

.componentContent {
  & > .contentLayout {
    @media (--viewport-md) {
      height: var(--height);
    }
  }
}

.contentLayout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-20);

  & > * {
    @supports not (gap: var(--size-20)) {
      margin-bottom: var(--size-20);
    }
  }

  @media (--viewport-md) {
    justify-content: center;
  }

  & > .title {
    order: 2;
  }

  & > .cta {
    order: 3;
    margin-top: var(--size-10);
  }

  & > .company {
    order: 4;
    align-self: flex-end;
    width: 70px;
    height: 70px;

    @media (--viewport-md) {
      width: 150px;
      height: 150px;
    }
  }

  & > .tags {
    order: 1;
  }
}

.title {
  @media (--viewport-md) {
    padding-right: calc(var(--height) * 0.3);
  }

  & > :not(:first-child) {
    margin-top: var(--size-10);

    @media (--viewport-md) {
      margin-top: var(--size-20);
      width: 80%;
    }
  }
}
