.componentJobs {
  --overview-grid-columns: 1;
}

.componentInspirations {
  --overview-grid-columns: 1;
}

.componentBase {
  @media (--viewport-md) {
    display: grid;
    gap: var(--size-50);
    grid-template-columns: repeat(var(--overview-grid-columns), minmax(auto, calc(var(--size-container-max-width-md) / var(--overview-grid-columns))));
    justify-content: center;
  }

  & > .item {
    height: 100%;
  }

  & > * {
    @media (--viewport-md) {
      min-width: 0;
    }
  }
}

.item {
  & > * {
    height: 100%;
  }
}

.pagination {
  padding-top: var(--size-30);

  @media (--viewport-md) {
    padding-top: var(--size-100);
  }
}
