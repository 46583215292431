.component {
  display: flex;
  gap: clamp(var(--size-30), 3.5vw, var(--size-50));
  color: var(--color-green-forrest);
}

.componentSubMenu {
  position: relative;

  &.isActive {
    position: relative;

    &::before {
      border-top: 4px solid var(--color-green-lime);
      content: '';
      position: absolute;
      height: 4px;
      width: 100%;
      top: -15px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  & > .wrapper {
    position: absolute;
    min-width: 330px;
    margin-left: calc(-1 * var(--size-100));
  }

  & > .button {
    height: var(--size-40);
  }
}

.menuItem {
  position: relative;
  padding: 0;

  & > * {
    height: var(--size-40);
  }
}

.button {
  display: flex;
  align-items: center;
  font-size: var(--font-size-16);
  line-height: 1;

  & > :not(:last-child) {
    margin-right: var(--size-10);
  }
}

.wrapper {
  background-color: var(--menu-desktop-background-color);
  color: var(--menu-desktop-color);
  border-radius: var(--size-20);
  padding: 0 var(--size-25);
  transition: height var(--duration-250) ease, opacity var(--duration-250) ease;
  position: relative;
  box-shadow: 0 calc(-1 * var(--size-10)) var(--size-20) rgba(0, 0, 0, 0.05);
  transform: translateY(var(--size-15));

  & > .triangle {
    position: absolute;
    top: -20px;
    left: 0;
    right: 0;
    height: 20px;
  }
}

.triangle {
  position: relative;
  color: var(--menu-desktop-background-color);

  & > * {
    position: absolute;
    bottom: 0;
    left: calc(50% - 21px);
    width: 42px;
    height: 13px;
  }
}

.list {
  padding-top: var(--size-25);
  padding-bottom: var(--size-25);
}

.componentSubMenuLink {
  --iconSize: 10px;

  position: relative;
  display: flex;
  align-items: center;

  &::before {
    position: absolute;
    content: '';
    background-color: var(--color-green-lime);
    width: var(--iconSize);
    height: var(--iconSize);
    border-radius: var(--iconSize);
    opacity: 0;
    transform: translateX(calc(-1 * (var(--iconSize) + var(--size-5))));
    transition: transform var(--duration-250) var(--ease-out-quad) var(--duration-50),
      opacity var(--duration-250) var(--ease-out-quad) var(--duration-50);
  }

  &:hover,
  &:focus {
    & > .linkText {
      transform: translateX(calc(var(--iconSize) + var(--size-5)));
    }

    &::before {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

.linkText {
  transition: transform var(--duration-250) var(--ease-out-quad);
}
