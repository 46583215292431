.component {
  --image-height: 570px;

  position: relative;
  z-index: 0;
  padding-bottom: var(--size-60);
  overflow: hidden;

  @media (--viewport-md) {
    padding-bottom: var(--size-90);
  }

  & > .layout {
    position: relative;
    z-index: 1;
  }

  & > .radialLayout {
    position: absolute;
    z-index: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.layout {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  gap: var(--size-20);

  & > * {
    @supports not (gap: var(--size-20)) {
      margin-bottom: var(--size-20);
    }
  }

  & > .contentLayout {
    order: 2;

    @media (--viewport-md) {
      position: relative;
      z-index: 2;
      height: var(--image-height);
    }
  }

  & > .heroGenericImageLayout {
    order: 1;
    max-height: var(--image-height);

    @media (--viewport-md) {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      height: var(--image-height);
    }
  }
}

.componentContent {
  & > .contentWrapper {
    @media (--viewport-md) {
      height: var(--image-height);
    }
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-20);

  & > * {
    @supports not (gap: var(--size-20)) {
      margin-bottom: var(--size-20);
    }
  }

  @media (--viewport-md) {
    justify-content: center;
  }

  & > .title {
    order: 2;
  }

  & > .ctaLayout {
    order: 3;
    margin-top: var(--size-10);
  }
}

.title {
  @media (--viewport-md) {
    padding-right: 40%;
  }
}
