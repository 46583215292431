.component {
  padding: var(--size-50) var(--size-10);
  font-size: var(--font-size-16);
  color: var(--color);
  background-color: var(--background-color);
  border-bottom-right-radius: var(--size-40);
  border-bottom-left-radius: var(--size-40);
  box-shadow: 0 24px 60px rgba(0, 0, 0, 0.25);
}

.layout {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: min-content;
  grid-template-areas:
    'nav0      nav1  '
    'nav2      social'
    'legal     logo  '
    'language  logo  ';
  gap: var(--size-50) var(--size-30);

  @media (--viewport-md) {
    gap: var(--size-30);
  }

  @media (--viewport-md) {
    grid-template-areas:
      'nav0      nav1      nav2     social  '
      'nav0      nav1      nav2     language'
      'legal     legal     legal    logo    ';
  }

  @media (--viewport-lg) {
    grid-template-columns: 1fr repeat(3, minmax(150px, max-content)) 1fr;
    grid-template-areas:
      'logo      nav0      nav1     nav2     social  '
      'logo      nav0      nav1     nav2     language'
      'logo      legal     legal    legal    legal   ';
    padding: 140px 0 75px;
    border-bottom-right-radius: var(--size-50);
    border-bottom-left-radius: var(--size-50);
  }

  & > * {
    min-width: 0;
  }

  & > .languageLayout {
    grid-area: language;
  }

  & > .legal {
    grid-area: legal;
    margin-top: var(--size-60);
  }

  & > .logo {
    grid-area: logo;
    max-width: 140px;
  }

  & > .nav0 {
    grid-area: nav0;
  }

  & > .nav1 {
    grid-area: nav1;
  }

  & > .nav2 {
    grid-area: nav2;
  }

  & > .social {
    grid-area: social;
  }

  & > .socialAndLanguage {
    justify-self: flex-end;
  }
}

.componentLegal {
  display: flex;
  flex-direction: column;
  line-height: 1.6;
  font-size: var(--font-size-14);
  opacity: 0.9;
}

.componentLegalAndLanguage {
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: var(--size-20);
  }
}

.componentNav {
  display: flex;
  flex-direction: column;
  line-height: 1.2;

  & > :not(:last-child) {
    margin-bottom: var(--size-20);

    @media (--viewport-md) {
      margin-bottom: 24px;
    }
  }
}

.title {
  font-size: var(--font-size-12);
  line-height: 1;
}

.componentSocial {
  display: flex;
  flex-direction: column;
  line-height: 1.2;

  & > :not(:last-child) {
    margin-bottom: var(--size-20);

    @media (--viewport-md) {
      margin-bottom: 24px;
    }
  }
}

.componentSocialAndLanguage {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & > :not(:last-child) {
    margin-bottom: var(--size-20);

    @media (--viewport-md) {
      margin-bottom: var(--size-30);
    }
  }
}

.socialNav {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  flex-wrap: wrap;
  gap: var(--size-20);

  @media (--viewport-xl) {
    grid-template-columns: repeat(4, max-content);
  }

  & > * {
    width: var(--size-30);
    min-width: 0;
    height: var(--size-30);

    @media (--viewport-md) {
      width: var(--size-40);
      height: var(--size-40);
    }
  }
}
