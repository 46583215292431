.component {
  position: relative;
  z-index: 0;
  animation-name: reveal;
  animation-duration: var(--duration-1500);
  animation-timing-function: var(--ease-out-quad);
  will-change: opacity, transform;

  & > .image {
    margin-left: auto;
    width: calc(100vw - var(--gutter-responsive));
    height: 100%;

    @media (--viewport-md) {
      width: calc(50vw - var(--gutter-responsive));
    }
  }
}

.image {
  border-radius: 100vw 0 0 100vw;
}

@keyframes reveal {
  0% {
    opacity: 0.2;
    transform: translateX(clamp(30px, 10vw, 150px));
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
