.componentBase {
  --max-size: 1200px;

  & > .layout {
    min-height: calc(var(--max-size) * 0.5);
    height: 100vw;
    max-height: var(--max-size);
  }
}

.layout {
  position: relative;
  z-index: 0;
  overflow: hidden;

  & > .radial {
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100vw;
    max-width: var(--max-size);

    &.radialCenter {
      @media (--viewport-md) {
        left: 0;
      }

      @media (--viewport-lg) {
        left: calc((100vw - var(--max-size)) * 0.5);
      }
    }

    &.radialRight {
      @media (--viewport-lg) {
        left: 50%;
      }
    }
  }
}

.radial {
  aspect-ratio: 1 / 1;
  background-image: radial-gradient(var(--radial-background-color), var(--color-transparent) 67%);
  animation-duration: var(--duration-1000);
  animation-delay: var(--duration-400);
  animation-timing-function: var(--ease-out-quad);
  animation-fill-mode: both;
}

.radialCenter {
  animation-name: radialCenter;
}

.radialRight {
  animation-name: radialRight;
}

@keyframes radialCenter {
  0% {
    transform: translate3d(0, -100%, 0);
  }

  100% {
    transform: translate3d(0, -45%, 0);
  }
}

@keyframes radialRight {
  0% {
    transform: translate3d(50%, -100%, 0);
  }

  100% {
    transform: translate3d(0, -45%, 0);
  }
}
