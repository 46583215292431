.component {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  color: var(--color-green-deep);
  border: 1px solid var(--color-gray-concrete);
  border-radius: 50%;
  overflow: hidden;

  & > .logo {
    width: var(--size-60);

    @media (--viewport-md) {
      width: clamp(var(--size-60), 22vw, 120px);
    }
  }
}
