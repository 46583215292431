.component {
  display: flex;
  flex-direction: column;
  padding-top: var(--size-50);

  @media (--viewport-md) {
    padding-top: var(--size-100);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-30);

    @media (--viewport-md) {
      margin-right: var(--size-60);
      margin-bottom: 0;
    }
  }

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > * {
    @media (--viewport-md) {
      flex-basis: 50%;
    }
  }

  & > .copy {
    @media (--viewport-md) {
      margin-top: var(--size-20);
      margin-bottom: var(--size-20);
      flex-basis: 40%;
    }
  }

  & > .employee {
    height: 100%;

    @media (--viewport-md) {
      flex-basis: 60%;
    }
  }
}

.componentCopy {
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: var(--size-20);
  }
}

.componentEmployee {
  display: flex;
  flex-direction: column;
  gap: var(--size-30);
  align-items: flex-start;
  padding: var(--size-20) var(--size-20) var(--size-40);
  border-radius: var(--size-20);
  background-color: var(--contrast-background-color);

  @media (--viewport-lg) {
    gap: var(--size-40);
  }

  @media (--viewport-lg) {
    flex-direction: row;
    padding: var(--size-40);
  }

  & > .avatar {
    flex-shrink: 0;
    align-self: center;
  }

  & > .details {
    @media (--viewport-lg) {
      align-self: center;
    }
  }
}

.details {
  display: flex;
  gap: var(--size-5);
  flex-direction: column;
  word-break: break-all;
  font-size: var(--font-size-16);

  & > .hubspot {
    margin-top: var(--size-10);
  }
}

.name {
  padding-bottom: var(--size-10);
}

.breakWord {
  word-break: break-word;
}

.componentAvatar {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;

  & > .image {
    width: 100px;
    width: clamp(100px, 60vw, 170px);

    @media (--viewport-lg) {
      width: clamp(100px, 60vw, 150px);
    }
  }

  & > .icon {
    position: absolute;
    width: 44px;
    right: var(--size-20);
    bottom: -8px;

    @media (--viewport-lg) {
      width: 53px;
      right: calc(var(--size-20) * -1);
      bottom: 8px;
    }
  }
}

.image {
  aspect-ratio: 1 / 1;
  border-radius: 50%;
}

.jobTitleAndLinks {
  display: flex;
  flex-direction: column;
}
