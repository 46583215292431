.component {
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-18);
  line-height: 1.2;
  text-transform: uppercase;

  & > :not(:last-child) {
    margin-right: var(--size-20);

    @media (--viewport-md) {
      margin-right: 24px;
    }
  }
}

.availableLanguage {
  position: relative;
  z-index: 0;

  &:active,
  &:hover,
  &:focus {
    text-decoration: underline 2px;
  }

  &::before {
      position: absolute;
      z-index: -1;
      top: -8px;
      right: -8px;
      bottom: -8px;
      left: -8px;
      content: '';
      display: block;
  }

  &:not(:last-child)::after {
      position: absolute;
      z-index: -2;
      top: -8px;
      right: -12px;
      bottom: -8px;
      display: block;
      width: 1px;
      content: '';
      border-right: 1px solid currentColor;
      opacity: 0.2;

  }

  &.isActive {
    font-weight: var(--font-weight-bold);
  }
}
