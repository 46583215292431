.component {
  padding: var(--size-10) var(--size-20);
  font-size: var(--font-size-16);
  color: var(--color-green-deep);
  background-color: var(--color-green-lime);
  border-radius: var(--size-20);
}

.componentCollapsible {
  overflow: hidden;
  transition: height var(--duration-250) ease;
}

.inner {
  & > .searchFieldset,
  & > .collapsibleCheckboxFieldset:not(:last-child),
  & > .componentCheckbox:last-child {
    margin-bottom: var(--size-20);
  }

  & > .componentCheckbox {
    width: 100%;
  }
}

.componentHeader {
  & > * {
    width: 100%;
    height: 44px;
  }
}

.headerSm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (--viewport-md) {
    display: none;
  }

  & > .iconCross {
    width: 14px;
    height: 14px;
  }

  & > .iconFilter {
    width: 24px;
    height: 24px;
  }
}

.headerMd {
  display: none;

  @media (--viewport-md) {
    display: flex;
    align-items: center;
  }
}

.componentSearchFieldset,
.componentCollapsibleCheckboxFieldset {
  position: relative;
  padding: 0 var(--size-20);
  background-color: var(--color-white);
  border: 0;
  border-radius: 22px;
}

.componentSearchFieldset {
  --fieldset-height: 44px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  & > .input {
    flex-grow: 1;
    height: var(--fieldset-height);
  }

  & > .iconSearch {
    width: 22px;
  }
}

.componentCollapsibleCheckboxFieldset {
  &.isActive {
    padding-bottom: var(--size-20);
  }

  & > .legendSm {
    width: 100%;

    @media (--viewport-md) {
      display: none;
    }
  }

  & > .legendMd {
    @media not (--viewport-md) {
      display: none;
    }
  }

  & > .collapsible {
    margin-bottom: var(--size-15);
  }
}

.icon {
  transition: transform var(--duration-250) var(--ease-out-expo);
  transform-origin: center;

  &.isFlipped {
    transform: rotate(180deg);
  }
}

.legend {
  padding: 12px 0;
}

.legendSm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > .iconCross {
    width: 14px;
    height: 14px;
  }
}

.componentCheckbox {
  display: flex;
  padding: var(--size-10) 0;
  align-items: center;

  & > * {
    margin-right: var(--size-10);
    flex-shrink: 0;
  }
}

.componentFooter {
  display: flex;
  flex-direction: column;

  & > .apply {
    margin-bottom: var(--size-30);

    @media (--viewport-md) {
      display: none;
    }
  }

  & > .reset {
    margin-bottom: var(--size-20);
    align-self: center;
  }
}

.reset {
  text-decoration: underline;
}
