.component {
  @media (--viewport-md) {
    display: flex;
    justify-content: center;
    padding: 0 var(--gutter);
    padding: 0 var(--gutter-responsive);
  }

  & > .layout {
    @media (--viewport-md) {
      width: 100%;
      max-width: var(--size-container-max-width-md);
    }
  }
}

.layout {
  @media (--viewport-md) {
    display: grid;
    gap: var(--size-70);
    grid-template-columns: 320px 1fr;
  }

  & > * {
    @media (--viewport-md) {
      min-width: 0;
    }
  }
}
