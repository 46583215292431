.component {
  --image-width: 1800px;

  position: relative;
  z-index: 0;
  animation-name: reveal;
  animation-duration: var(--duration-1500);
  animation-timing-function: var(--ease-out-quad);
  will-change: opacity, transform;

  & > .image {
    width: var(--image-width);
    height: 100%;

    @media (--viewport-md) {
      max-width: var(--size-container-max-width-lg);
    }

    @media (--viewport-xl) {
      max-width: var(--image-width);
    }
  }

  &::after {
    @media (--viewport-md) {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: var(--color-black);
      opacity: 0.3;
      border-top-right-radius: 100vw;
      border-bottom-right-radius: 100vw;
    }
  }
}

.image {
  border-radius: 0 100vw 100vw 0;

  @media (--viewport-md) {
    border-top-right-radius: 100vw;
    border-bottom-right-radius: 100vw;
  }
}

@keyframes reveal {
  0% {
    opacity: 0.2;
    transform: translateX(clamp(-90px, 10vw, -200px));
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
