.page {
  & > .title {
    margin-top: var(--size-40);
    margin-bottom: var(--size-20);
  }
}

.title {
  font-size: var(--font-size-40);
  font-weight: var(--font-weight-bold);
}

.componentContent {
  & > * {
    margin-top: var(--size-40);
    margin-bottom: var(--size-40);

    @media (--viewport-md) {
      margin-top: var(--size-70);
      margin-bottom: var(--size-70);
    }
  }

  & > :first-child {
    margin-top: 0;
  }
}
