.component {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.extra {
  display: flex;
  align-items: center;
  gap: var(--size-40);
  color: var(--color-green-forrest);
}

.componentSubMenuLink {
  font-size: var(--font-size-16);
}
