.componentBase {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: var(--size-30) var(--gutter);
  padding: var(--size-30) var(--gutter-responsive);
  border-radius: var(--size-20);
  overflow: hidden;
  transition: background var(--duration-250) var(--ease-out-expo);

  @media (--viewport-md) {
    padding: var(--size-30) var(--size-40) var(--size-40);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-20);

    @media (--viewport-md) {
      margin-bottom: var(--size-10);
    }
  }

  &::before {
    position: absolute;
    z-index: 0;
    top: 0;
    right: var(--gutter);
    right: var(--gutter-responsive);
    left: var(--gutter);
    left: var(--gutter-responsive);
    height: 1px;
    content: '';
    background-color: currentColor;
    opacity: 0.2;
    transition: opacity var(--duration-250) var(--ease-out-expo);

    @media (--viewport-md) {
      right: var(--size-40);
      left: var(--size-40);
    }
  }

  &:focus-within,
  &:hover {
    background-color: var(--contrast-background-color);

    &::before {
      opacity: 0;
    }
  }

  & > .button {
    margin-top: var(--size-10);
    align-self: flex-start;
  }

  & > .link {
    &.relativeToParent {
      position: static;
    }
  }

  & > .meta {
    order: -1;
  }

  & > .intro {
    flex-grow: 1;
  }
}

.link {
  font-size: 0;
  position: relative;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.meta {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: var(--font-size-12);

  & > :not(:last-child) {
    margin-right: var(--size-10);
  }
}
