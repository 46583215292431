.componentBase {
  line-height: var(--line-height-text);
}

.componentSm {
  font-size: var(--font-size-14);
  font-size: var(--font-size-14-16);
}

.componentMd {
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-20);
}

.componentLg {
  font-size: var(--font-size-20);
  font-size: var(--font-size-20-24);
}
