.component {
  position: relative;
  z-index: 0;
  font-size: var(--font-size-14);

  & > .copyButtonLayout {
    position: absolute;
    z-index: 1;
    top: var(--size-25);
    right: var(--size-25);
  }
}
