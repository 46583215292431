.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-40);
}

.cardsContainer {
  --card-title-font-size: var(--font-size-24-40);

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--size-30);

  & > .cardLayout {
    width: 100%;
  }

  &:has(.cardLayout:nth-child(2)) {
    & > .cardLayout {
      @media (--viewport-md) {
        width: calc(100% / 2 - var(--size-30));
      }
    }
  }

  &:has(.cardLayout:nth-child(3)) {
    --card-title-font-size: var(--font-size-16-25);

    & > .cardLayout {
      @media (--viewport-lg) {
        width: calc(100% / 3 - var(--size-30));
      }
    }
  }

  &:has(.cardLayout:nth-child(4)) {
    & > .cardLayout {
      @media (--viewport-lg) {
        width: calc(100% / 2 - var(--size-30));
      }
    }
  }

  &:has(.cardLayout:nth-child(n + 5)) {
    & > .cardLayout {
      @media (--viewport-lg) {
        width: calc(100% / 3 - var(--size-30));
      }
    }
  }
}

.header {
  text-align: center;
}

.componentCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--card-background-color);
  color: var(--card-text-color);
  padding: var(--size-40) var(--size-30);
  border-radius: var(--size-20);
  transition: background-color var(--duration-150) ease-out;

  &:has(.buttonLayout) {
    &:hover,
    &:focus {
      background-color: var(--card-background-color-hover);
    }

    & > .contentContainer {
      margin-bottom: var(--size-30);
    }
  }

  & > .buttonLayout {
    margin-top: auto;
  }

  & > .imageContainer {
    height: var(--size-70);
    width: var(--size-70);
    margin-bottom: var(--size-20);
  }
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: var(--size-15);
}

.cardTitle {
  font-size: var(--card-title-font-size);
  line-height: var(--line-height-title);
  font-weight: var(--font-weight-bold);
}

.imageContainer {
  & > .imageContainerFigCaption {
    display: none;
  }
}
