@value _logoSizeSm: 114px;
@value _logoSizeMd: 150px;
@value _logoSpacing: 10px;

:export {
  logoSizeSm: _logoSizeSm;
  logoSizeMd: _logoSizeMd;
}

.component {
  & > .slider {
    width: 100%;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > .title {
    max-width: 580px;
  }
}

.slider {
  padding-top: var(--size-30);
  height: calc(var(--size-30) + _logoSizeSm + _logoSpacing);

  @media (--viewport-md) {
    height: calc(var(--size-30) + _logoSizeMd + _logoSpacing);
  }
}

.componentSlide {
  & > .containerLogo {
    width: calc(_logoSizeSm + _logoSpacing);
    height: calc(_logoSizeSm + _logoSpacing);

    @media (--viewport-md) {
      width: calc(_logoSizeMd + _logoSpacing);
      height: calc(_logoSizeMd + _logoSpacing);
    }
  }
}

.containerLogo {
  overflow: hidden;
  border: 1px solid var(--color-gray-concrete);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  padding: var(--size-20);
  margin: 0 _logoSpacing;
}

.componentControls {
  display: flex;
  justify-content: flex-end;
  padding-top: var(--size-30);

  @media (--viewport-md) {
    padding-top: 0;
  }

  & > :not(:last-child) {
    margin-right: var(--size-20);
  }
}

.arrow {
  &.disabled {
    opacity: 0.2;
  }
}

.buttonIcon {
  & > * {
    width: 35px;
    height: auto;

    @media (--viewport-md) {
      width: 100%;
    }
  }
}
