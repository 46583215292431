.component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  & > :not(:last-child) {
    margin-bottom: var(--size-30);

    @media (--viewport-md) {
      margin-bottom: 0;
    }
  }

  @media (--viewport-md) {
    flex-direction: row;
    align-items: center;
    padding: 0 var(--gutter);
    padding: 0 var(--gutter-responsive);
  }

  & > * {
    @media (--viewport-md) {
      width: 50%;
      max-width: calc(var(--size-container-max-width-md) * 0.5);
    }
  }

  & > .animation {
    order: 1;
    max-height: 525px;
    margin-bottom: var(--size-30);

    @media (--viewport-md) {
      margin-bottom: 0;
      height: calc((13 / 20) * 55vw);
    }
  }

  & > .content {
    order: 2;
  }

  &.isReversed {
    & > .animation {
      margin-bottom: var(--size-30);

      @media (--viewport-md) {
        order: 2;
        margin-bottom: 0;
      }
    }

    & > .content {
      @media (--viewport-md) {
        order: 1;
      }
    }
  }
}

.content {
  padding: 0 var(--gutter);
  padding: 0 var(--gutter-responsive);

  @media (--viewport-md) {
    padding: 0;
  }

  & > .button {
    margin-top: var(--size-30);
  }
}
