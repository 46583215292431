.component {
  background-color: var(--color-white);

  @media (--viewport-lg) {
    box-shadow: 0 var(--size-10) var(--size-20) rgba(0, 0, 0, 0.05);
  }
}

.container {
  padding-right: 15px;
  padding-left: 15px;

  @media (--viewport-lg) {
    padding-right: max(5vw, 20px);
    padding-left: max(5vw, 20px);
  }
}

.layout {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > .navigationMobile {
    height: var(--site-header-mobile-height);
  }

  & > .navigationDesktop {
    height: var(--site-header-desktop-height);
    flex-grow: 1;
  }

  & > .logo {
    flex-shrink: 0;

    @media (--viewport-lg) {
      margin-right: clamp(var(--size-50), 4vw, var(--size-100));
      width: 117px;
    }
  }
}

.navigationMobile {
  @media (--viewport-lg) {
    display: none;
  }
}

.navigationDesktop {
  @media not (--viewport-lg) {
    display: none;
  }
}
