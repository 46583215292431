.component {
  position: relative;
  z-index: 0;

  & > .controls {
    margin-top: var(--size-20);

    @media (--viewport-md) {
      margin-top: 0;
      position: absolute;
      bottom: var(--size-20);
      left: 0;
      right: 0;
      z-index: 1;
    }
  }

  & > .slider {
    width: 100%;
    height: 100%;
  }
}

.slider {
  display: flex;
  align-items: center;

  & > .slide {
    height: 325px;

    &.firstSlide {
      margin-left: var(--gutter);
      margin-left: var(--gutter-responsive);
    }

    @media (--viewport-md) {
      height: 600px;
    }
  }
}

.slide {
  overflow: hidden;
  border-radius: 100px;

  @media (--viewport-md) {
    border-radius: 300px;
  }

  & > .imageMobile {
    @media (--viewport-md) { display: none; }
  }

  & > .imageDesktop {
    @media not (--viewport-md) { display: none; }
  }

  & > * {
    height: 100%;
  }
}

.componentControls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerControls {
  @media (--viewport-md) {
    background: rgba(255, 255, 255, 0.01);
    mix-blend-mode: normal;
    backdrop-filter: blur(var(--size-20));
    border-radius: var(--size-100);
    padding: var(--size-15);
  }

  & > :not(:last-child) {
    margin-right: var(--size-10);
  }

  & > .button {
    width: var(--size-10);
    height: var(--size-10);

    &.isActive {
      width: 70px;
    }
  }
}

.button {
  background-color: var(--color-white);
  border-radius: 100px;
  position: relative;
}
