.component {
  position: relative;
  z-index: 0;
  display: flex;

  &:not(.isReversed) {
    justify-content: flex-end;
  }

  &.isReversed {
    justify-content: flex-start;
  }

  & > .mask {
    width: 0;
  }
}

.animation {
  aspect-ratio: 20 / 13;
}

.image {
  clip-path: url(#imageWithTextMask);

  &.isReversed {
    clip-path: url(#imageWithTextMaskReversed);
  }
}
