.component {
  padding-top: var(--site-header-mobile-height);
  overflow-y: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;

  & > .extra {
    margin-top: var(--size-40);
    margin-bottom: var(--size-40);
  }
}

.items {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    width: 100%;
  }
}

.item {
  display: flex;
  position: relative;
  padding: 0;

  &::after {
    content: '';
    border-bottom: 1px solid var(--color-gray-concrete);
    position: absolute;
    height: 1px;
    right: var(--size-20);
    bottom: 0;
    left: var(--size-20);
  }

  & > * {
    width: 100%;
  }
}

.componentSubMenu {
  padding: var(--size-20);
  color: var(--color-green-forrest);

  & > .expand {
    &.isExpanded {
      margin-top: var(--size-20);
    }
  }

  &.isExpanded {
    color: var(--color-white);
    background-color: var(--color-green-forrest);
  }

  & > .button {
    width: 100%;
  }
}

.icon {
  transition: transform var(--duration-250) ease;

  &.isRotated {
    transform: rotate(180deg);
  }
}

.innerMenu {
  padding-left: var(--size-20);

  & > :not(:last-child) {
    margin-bottom: var(--size-20);
  }
}

.button {
  font-size: var(--font-size-20);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.componentSubMenuLink {
  display: flex;
  align-items: center;
}

.extra {
  display: flex;
  flex-direction: column;
  gap: var(--size-40);
  padding-left: var(--size-20);
  padding-right: var(--size-20);
  color: var(--color-green-forrest);

  & > .languageLayout {
    align-self: center;
  }
}

.expand {
  pointer-events: none;

  &.isExpanded {
    pointer-events: auto;
  }
}
