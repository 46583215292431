.component {
  --image-height: 570px;

  position: relative;
  z-index: 0;
  padding-bottom: var(--size-60);

  @media (--viewport-md) {
    padding-bottom: var(--size-90);
  }

  & > .layout {
    position: relative;
    z-index: 1;
  }

  & > .radial {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}

.layout {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  gap: var(--size-20);

  & > * {
    @supports not (gap: var(--size-20)) {
      margin-bottom: var(--size-20);
    }
  }

  & > .content {
    order: 2;

    @media (--viewport-md) {
      position: relative;
      z-index: 2;
      height: var(--image-height);
    }
  }

  & > .imageAndCompany {
    order: 1;
    max-height: var(--image-height);
    margin-right: var(--gutter);
    margin-right: var(--gutter-responsive);

    @media (--viewport-md) {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      height: var(--image-height);
    }

    @media (--viewport-xl) {
      right: calc(45vw - (var(--size-container-max-width-lg) * 0.4));
    }
  }
}

.componentContent {
  & > .contentLayout {
    @media (--viewport-md) {
      height: var(--image-height);
    }
  }
}

.componentResponsiveButton {
  &.isMd {
    display: none;

    @media (--viewport-md) {
      display: block;
    }
  }

  &:not(.isMd) {
    @media (--viewport-md) {
      display: none;
    }
  }
}

.contentLayout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-20);

  & > * {
    @supports not (gap: var(--size-20)) {
      margin-bottom: var(--size-20);
    }
  }

  @media (--viewport-md) {
    justify-content: center;
  }

  & > .title {
    order: 2;
  }

  & > .cta {
    order: 3;
    margin-top: var(--size-10);
  }

  & > .tags {
    order: 1;
  }
}

.title {
  @media (--viewport-md) {
    padding-right: calc(var(--image-height) * 0.3);
    color: var(--color-white);
  }

  & > :not(:first-child) {
    margin-top: var(--size-10);

    @media (--viewport-md) {
      margin-top: var(--size-20);
      width: 80%;
    }
  }
}

.imageAndCompany {
  position: relative;
  z-index: 0;

  @media (--viewport-md) {
    display: flex;
    justify-content: flex-end;
  }

  & > .company {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    width: 70px;
    height: 70px;

    @media (--viewport-md) {
      width: 150px;
      height: 150px;
    }
  }

  & > .heroContentImage {
    height: 100%;
  }
}
