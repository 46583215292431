.componentBase {
  & > .icon {
    width: 100%;
    min-width: 97px;
  }
}

.icon {
  display: block;
}
