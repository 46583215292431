.componentBase {
  font-size: var(--font-size-12);
  font-size: var(--font-size-12-16);

  @media (--viewport-md) {
    display: inline-block;
    font-size: var(--font-size-16);
  }
}

.componentTagsWithBackdrop {
  @media (--viewport-md) {
    padding: var(--size-10) var(--size-20);
    color: var(--color-white);
    background-color: rgba(255, 255, 255, 0.01);
    backdrop-filter: blur(19px);
    border-radius: var(--size-20);
  }
}

.tag {
  display: inline;

  &:not(:last-child)::after {
    display: inline;
    content: '•';
    padding: 0 var(--size-10);
  }
}
