.page {
  & > .inspirations {
    margin-bottom: var(--size-100);
  }
}

.componentContent {
  & > * {
    margin-top: var(--size-40);
    margin-bottom: var(--size-40);

    @media (--viewport-md) {
      margin-top: var(--size-70);
      margin-bottom: var(--size-70);
    }
  }

  & > :first-child {
    margin-top: 0;
  }
}
