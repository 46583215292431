.component {
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .description {
    width: 100%;
    margin-bottom: var(--size-60);

    @media (--viewport-md) {
      width: 33.33%;
      margin-right: var(--size-100);
      margin-bottom: 0;
    }
  }

  & > .faq {
    width: 100%;

    @media (--viewport-md) {
      width: 66.66%;
    }
  }
}

.faq {
  & > :not(:last-child) {
    margin-bottom: var(--size-30);
  }
}

.description {
  & > :not(:last-child) {
    margin-bottom: var(--size-30);
  }
}

.componentItem {
  position: relative;
  padding-bottom: var(--size-30);

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    right: 0;
    left: 0;
    height: 1px;
    background-color: var(--color);
    opacity: 0.3;
  }
}

.heading {
  font-size: var(--font-size-20);
  font-size: var(--font-size-20-24);

  & > .toggle {
    width: 100%;
  }
}

.toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding-right: var(--size-20);

  &::after {
    content: '';
    position: absolute;
    right: 0;
    width: 20px;
    height: 22px;
    mask-image: url('/images/icons/arrow-down.svg');
    background-color: var(--color);
    transition: transform var(--duration-250) var(--ease-out-expo);
  }

  &.isActive {
    &::after {
      transform: rotate(180deg);
    }
  }
}

.answer {
  overflow: hidden;
  transition: height var(--duration-250) ease;
}

.inner {
  padding-top: var(--size-30);

  & > :not(:last-child) {
    margin-bottom: var(--size-10);
  }
}
