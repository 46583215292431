.component {
  position: relative;
  z-index: 0;

  & > :not(.hero) {
    position: relative;
    z-index: 1;
  }

  & > .intro {
    margin-top: var(--size-5);
    margin-bottom: var(--size-50);

    @media (--viewport-md) {
      margin-top: var(--size-10);
      margin-bottom: var(--size-100);
    }
  }
}

.componentContent {
  & > * {
    margin-top: var(--size-40);
    margin-bottom: var(--size-40);

    @media (--viewport-md) {
      margin-top: var(--size-70);
      margin-bottom: var(--size-70);
    }

    @media (--viewport-lg) {
      margin-top: var(--size-100);
      margin-bottom: var(--size-100);
    }
  }

  & > :first-child {
    margin-top: 0;
  }
}
