.component {
  display: flex;
  align-items: center;
  gap: var(--size-10);

  @supports not (gap: var(--size-10)) {
    & > :not(:last-child) {
      margin-right: var(--size-10);
    }
  }
}
