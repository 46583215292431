.component {
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding-top: var(--size-70);
  padding-bottom: var(--size-70);
  background-color: var(--background-color);
  color: var(--color);

  &.hasBackgroundColor {
    border-radius: var(--size-30);

    @media (--viewport-md) {
      border-radius: var(--size-50);
    }
  }

  &:not(.hasBackgroundColor) {
    & > .radial {
      display: none;
    }
  }

  & > * {
    position: relative;
    z-index: 1;
  }

  & > .radial {
    position: absolute;
    z-index: 0;
    top: 50%;
    right: 0;
    min-height: 600px;
    height: 100vw;
    max-height: 1200px;
  }
}

.radial {
  aspect-ratio: 1 / 1;
  background-image: radial-gradient(var(--radial-background-color), var(--color-transparent) 67%);
  transform: translate(50%, -50%);
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > .title {
    @media (--viewport-md) {
      max-width: 55%;
    }
  }
}

.containerInner {
  & > .slider {
    margin-top: var(--size-20);

    @media (--viewport-md) {
      margin-top: var(--size-60);
    }
  }
}

.slider {
  display: flex;
  overflow: visible !important;

  & > .slide {
    &.firstSlide {
      @media (--viewport-md) {
        margin-left: -25px;
      }
    }
  }
}

.componentSlide {
  --dot-color: var(--background-color);

  display: grid;
  align-items: start;
  grid-auto-rows: auto;
  padding: var(--size-30);
  background-color: var(--contrast-background-color);
  border-radius: var(--size-30);
  position: relative;
  z-index: 0;

  @media (--viewport-md) {
    --dot-color: var(--contrast-background-color);

    background: none;
    color: var(--color);
  }

  &:focus-within,
  &:hover {
    @media (--viewport-md) {
      --dot-color: var(--background-color);

      background-color: var(--contrast-background-color);
    }
  }

  & > .figureLayout {
    width: 100%;
  }

  & > .figureLayout,
  & > .headingLayout {
    margin-bottom: var(--size-20);
  }

  & > .textLayout {
    margin-bottom: var(--size-10);
  }

  & > .buttonLayout {
    height: min-content;
    align-self: end;
    z-index: 2;
  }

  & > .linkButtonLayout {
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0;
    z-index: 1;
  }
}

.componentFigure {
  aspect-ratio: 300 / 173;
  overflow: hidden;

  & > * {
    height: 100%;
    width: 100%;
  }
}

.image {
  border-radius: 100vmax;
  overflow: hidden;

  & > * {
    height: 100%;
    width: 100%;
  }
}

.containerDots {
  aspect-ratio: 300 / 173;
  color: var(--dot-color);
}

.componentLinkButton {
  font-size: 0;
}

.componentLineClamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text {
  opacity: 0.7;
}

.arrow {
  color: var(--color);

  &.disabled {
    opacity: 0.2;
  }
}

.componentControls {
  display: flex;
  justify-content: flex-end;

  & > .arrow {
    width: 35px;

    @media (--viewport-md) {
      width: auto;
    }
  }

  & > :not(:last-child) {
    margin-right: var(--size-20);
  }
}
